// src/PrivacyPolicy.js

import React from "react";

function PrivacyPolicy() {
  return (
    <div style={{ padding: "20px" }}>
      <h1>Privacy Policy</h1>
      <p>Last updated: 23/07/2024</p>

      <h2>Introduction</h2>
      <p>
        Welcome to Baitek.ae. This Privacy Policy explains how we collect, use,
        disclose, and safeguard your information when you use our application
        for the UAE market. By using Baitek.ae, you agree to the collection and
        use of information in accordance with this policy.
      </p>

      <h2>Information We Collect</h2>
      <p>We may collect and process the following types of information:</p>
      <ul>
        <li>
          <strong>Personal Identification Information:</strong> Name, email
          address, phone number, and other similar information.
        </li>
        <li>
          <strong>Business Information:</strong> Company name, business address,
          and other relevant business details.
        </li>
        <li>
          <strong>Usage Data:</strong> Information about how you use the app,
          such as your interactions with other users, companies, and the Baitek
          team.
        </li>
        <li>
          <strong>Request Data:</strong> Information related to requests for
          quotations, reviews, and project guidance.
        </li>
      </ul>

      <h2>How We Use Your Information</h2>
      <p>We use the collected information for various purposes, including:</p>
      <ul>
        <li>To provide and maintain our app.</li>
        <li>
          To facilitate communication between users and construction companies.
        </li>
        <li>To process user reviews and ratings of construction companies.</li>
        <li>
          To manage user requests for quotations and responses from companies.
        </li>
        <li>
          To offer assistance and guidance for user construction projects.
        </li>
        <li>To improve our services, features, and user experience.</li>
        <li>
          To send you updates, notifications, and other information related to
          your use of the app.
        </li>
      </ul>

      <h2>Disclosure of Your Information</h2>
      <p>We may share your information in the following situations:</p>
      <ul>
        <li>
          With construction companies to facilitate quotations and service
          requests.
        </li>
        <li>
          With third-party service providers to perform functions on our behalf,
          such as data analysis and customer service.
        </li>
        <li>
          To comply with legal obligations and protect our rights and safety, as
          well as those of our users.
        </li>
      </ul>

      <h2>Security of Your Information</h2>
      <p>
        We use administrative, technical, and physical security measures to help
        protect your personal information. However, no security system is
        impenetrable, and we cannot guarantee the absolute security of your
        information.
      </p>

      <h2>Your Privacy Rights</h2>
      <p>
        You have the right to access, update, and delete your personal
        information. You can also object to certain data processing practices.
        To exercise these rights, please contact us at [Insert Contact
        Information].
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. You are
        advised to review this Privacy Policy periodically for any changes.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at:
      </p>
      <p>Email: info@wajaha.ae</p>
      <p>Phone: 0528541477</p>

      <p>Thank you for using Baitek.ae!</p>
    </div>
  );
}

export default PrivacyPolicy;
