// src/Contact.js

import React from "react";
import baitekGif from "./baitekGif.gif"; // Import the logo

function Contact() {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#f9f9f9", // White background
    color: "#333" // Darker text for contrast
  };

  const contentStyle = {
    maxWidth: "800px", // Constrain the width of the content
    width: "100%",
    padding: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    backgroundColor: "#ffffff"
  };

  const headerStyle = {
    color: "#b8860b", // Darker gold color for headers
    borderBottom: "2px solid #b8860b",
    paddingBottom: "10px",
    marginBottom: "20px"
  };

  const listStyle = {
    listStyleType: "none",
    padding: 0
  };

  const listItemStyle = {
    padding: "10px 0",
    borderBottom: "1px solid #f1f1f1"
  };

  const contactLinkStyle = {
    color: "#b8860b", // Darker gold color for links
    textDecoration: "none"
  };

  const logoStyle = {
    width: "150px",
    marginBottom: "20px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto"
  };

  const formStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    marginTop: "20px"
  };

  const inputStyle = {
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "16px"
  };

  const textareaStyle = {
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "16px",
    resize: "vertical"
  };

  const buttonStyle = {
    backgroundColor: "#b8860b", // Darker gold color for button
    color: "#fff",
    padding: "10px 15px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    alignSelf: "flex-start"
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <img src={baitekGif} alt="Baitek Logo" style={logoStyle} />
        <h1 style={headerStyle}>Contact Us</h1>

        <h2 style={headerStyle}>Get in Touch</h2>
        <p>
          We’d love to hear from you! Whether you have a question about our
          services, need assistance with your construction project, or just want
          to give feedback, feel free to reach out to us using the details
          below.
        </p>

        <h2 style={headerStyle}>Contact Information</h2>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Email:</strong>{" "}
            <a href="mailto:info@wajaha.ae" style={contactLinkStyle}>
              info@wajaha.ae
            </a>
          </li>
          <li style={listItemStyle}>
            <strong>Phone:</strong> 0528541477
          </li>
          <li style={listItemStyle}>
            <strong>Office Address:</strong> Wajaha Building, 5th Floor, Sheikh
            Zayed Road, Dubai, UAE
          </li>
        </ul>

        <h2 style={headerStyle}>Business Hours</h2>
        <p>Our team is available to assist you during the following hours:</p>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <strong>Sunday - Thursday:</strong> 9:00 AM - 6:00 PM
          </li>
          <li style={listItemStyle}>
            <strong>Friday:</strong> 9:00 AM - 12:00 PM
          </li>
          <li style={listItemStyle}>
            <strong>Saturday:</strong> Closed
          </li>
        </ul>

        <h2 style={headerStyle}>Send Us a Message</h2>
        <p>
          If you prefer, you can also send us a message directly through our
          website by filling out the form below. We’ll get back to you as soon
          as possible.
        </p>

        <form style={formStyle}>
          <input
            type="text"
            placeholder="Your Name"
            style={inputStyle}
            required
          />
          <input
            type="email"
            placeholder="Your Email"
            style={inputStyle}
            required
          />
          <input
            type="text"
            placeholder="Subject"
            style={inputStyle}
            required
          />
          <textarea
            placeholder="Your Message"
            rows="5"
            style={textareaStyle}
            required
          ></textarea>
          <button type="submit" style={buttonStyle}>
            Send Message
          </button>
        </form>

        <p style={{ marginTop: "20px" }}>
          Thank you for reaching out to Baitek.ae. We’re here to help!
        </p>
      </div>
    </div>
  );
}

export default Contact;
